/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'

// Images
import Plaatjie from '@ubo/plaatjie'

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;
  background-color: ${({ theme }) => theme.color.primary};

  @media (min-width: 992px) {
    padding: 6.5rem 4rem;
    margin-top: -15rem;
    margin-bottom: 6rem;
    min-height: 900px;
  }

  @media (max-width: 991px) {
    padding: 1.5rem 2rem;
  }

  & .form-duuf-group {
    position: relative;
    margin-bottom: 2rem;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: hsl(0, 0%, 80%);
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    padding: 1rem;
  }

  & .form-duuf-textarea {
    height: 100px;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    display: none;
  }

  & .form-duuf-error {
    position: absolute;
    right: 18px;
    top: 5px;
    color: tomato;
  }

  & .form-duuf-submit {
    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    font-size: ${({ theme }) => theme.font.size.big};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    text-align: center;
    color: ${({ theme }) => theme.color.dark};
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const SubmitWrapper = styled.div`
  ${ButtonPrimaryCSS}
  width: 220px;

  & .form-duuf-group-submit {
    margin-bottom: 0 !important;
    padding: 10px 8px;

    & button {
      color: ${({ theme }) => theme.color.light} !important;
    }
  }

  &:hover {
    & svg {
      position: relative;
      z-index: 2;
      & path {
        fill: ${({ theme }) => theme.color.dark};
      }
    }

    & button {
      color: ${({ theme }) => theme.color.dark} !important;
    }
  }
`

const Content = styled(ParseContent)`
  & a {
    color: ${({ theme }) => theme.color.dark};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${({ theme }) => theme.color.dark};
    }
  }
`
const Social = styled.a`
  &:hover {
    opacity: 0.85;
  }
`

interface FormContactProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
}

const FormContact: React.FC<FormContactProps> = ({ fields }) => (
  <section>
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-lg-9">
          <StyledFormDuuf id={1} generate={false}>
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="row">
                  <div className="col-lg-6">
                    <Content content={fields.description} className="mb-4" />
                  </div>
                  <div className="col-lg-6 mb-4 mb-lg-0">
                    <Content
                      content={fields.info?.description}
                      className="mb-4"
                    />
                    {fields.info?.socials?.map((social, index) => (
                      <Social
                        href={social?.link?.url}
                        className="text-hidden me-3"
                        key={index}
                      >
                        {social?.link?.title}
                        <Plaatjie image={social?.icon} alt="" />
                      </Social>
                    ))}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <FormDuufGroup showIds={[4, 6]} />
                  </div>
                  <div className="col-lg-6">
                    <FormDuufGroup showIds={[8, 1]} />
                  </div>
                </div>
                <div className="row">
                  <FormDuufGroup showIds={[2]} />
                </div>
                <SubmitWrapper className="d-flex align-items-center justify-content-center">
                  <FormDuufSubmit />
                </SubmitWrapper>
              </div>
            </div>
          </StyledFormDuuf>
        </div>
      </div>
    </div>
  </section>
)

export default FormContact
